import website from '../assets/website.png';
import instagram from '../assets/instagram.png';
import linkedin from '../assets/linkedin.jpg';
import youtube from '../assets/youtube.jpeg';
import dribbble from '../assets/dribbble.png';
import github from '../assets/github.png';
import telegram from '../assets/telegram.png';
import twitter from '../assets/twitter.png';
import mywebsite from '../assets/mywebsite.png'
import gmail from '../assets/Gmail.png';
import nus from '../assets/NUS com3.png';
import accredible from '../assets/accredible2.png';
import link from '../assets/link2.png'
import namecard from '../assets/nfc-card.png'

import { CHANNEL_TYPES } from '../constants';

export const CHANNEL_TYPE_VS_COVER_IMAGE = {
  [CHANNEL_TYPES.WEBSITE]: website,
  [CHANNEL_TYPES.INSTAGRAM]: instagram,
  [CHANNEL_TYPES.LINKEDIN]: linkedin,
  [CHANNEL_TYPES.YOUTUBE]: youtube,
  [CHANNEL_TYPES.DRIBBLE]: dribbble,
  [CHANNEL_TYPES.GITHUB]: github,
  [CHANNEL_TYPES.TELEGRAM]: telegram,
  [CHANNEL_TYPES.TWITTER]: twitter,
  [CHANNEL_TYPES.MYWEBSITE]: mywebsite,
  [CHANNEL_TYPES.GMAIL]: gmail,
  [CHANNEL_TYPES.NUS]: nus,
  [CHANNEL_TYPES.ACCREDIBLE]: accredible,
  [CHANNEL_TYPES.LINK]: link,
  [CHANNEL_TYPES.NAMECARD]: namecard,
};
