import React from 'react';

import '../../css/skeleton.css';
import '../../css/components.css';

function Footer(): JSX.Element {
  return (
    <div className="Footer container">
      <p>
        {/* Made with <span className="heart">❤️</span> by <a href={'https://twitter.com/heysagnik'}>Sagnik Sahoo</a> */}
        © 2023 Nicholas Chung.
      </p>
      <br />
      {/* added line break for nicer design */}
    </div>
  );
}

export default Footer;
