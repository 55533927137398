import React from 'react';
import ReactDOM from 'react-dom';
import { CHANNEL_TYPES }  from './packages/Linkees/src/constants';
import Linkees from "./packages/Linkees/src"
import "./packages/Linkees/src/constants.ts"
import './css/normalize.css';

const items = [
  {
    title: 'Website',
    subtitle: 'View my full portfolio website',
    type: CHANNEL_TYPES.MYWEBSITE,
    link: 'https://portfolio.chungnicholas.com',
  },
  {
    title: 'GitHub',
    subtitle: 'My github profile',
    type: CHANNEL_TYPES.GITHUB,
    link: 'https://github.com/chungnicholas', //Github Profile link
  },
  {
    title: 'LinkedIn',
    subtitle: 'Connect with me on Linkedin!',
    type: CHANNEL_TYPES.LINKEDIN,
    link: 'https://www.linkedin.com/in/chungnicholas/', // linkedin
  },
  {
    title: 'NUS School of Computing',
    subtitle: 'View my Resume here',
    type: CHANNEL_TYPES.NUS,
    link: 'https://resume.chungnicholas.com',
  },
  {
    title: 'Accredible',
    subtitle: 'View my credentials wallet here',
    type: CHANNEL_TYPES.ACCREDIBLE,
    link: 'https://www.credential.net/profile/chungnicholas/wallet',
  },
  {
    title: 'Email',
    subtitle: 'Contact me via email!',
    type: CHANNEL_TYPES.GMAIL,
    link: 'mailto:careers@chungnicholas.com',
  },
  {
    title: 'Telegram bot',
    subtitle: '@SGtrafficbot | For all your traffic news',
    type: CHANNEL_TYPES.TELEGRAM,
    link: 'http://t.me/SGtrafficbot', //Telegram Bot
  },
  {
    title: 'URL shortener',
    subtitle: 'My personal URL shortener',
    type: CHANNEL_TYPES.LINK,
    link: 'https://link.chungnicholas.com', 
  },
  {
    title: 'Smart name card',
    subtitle: 'Built with NFC technology',
    type: CHANNEL_TYPES.NAMECARD,
    link: 'https://portfolio.chungnicholas.com/#namecard', 
  }
  // {
  //   title: 'ScamProtect App',
  //   subtitle: 'Scam prevention app inspired by Govtech\'s Scam Shield',
  //   type: CHANNEL_TYPES.YOUTUBE,
  //   link: 'https://youtu.be/R19pFsICr4A', 
  // },
  // {
  //   title: 'Top 1 lengzai',
  //   subtitle: 'Me',
  //   type: CHANNEL_TYPES.WEBSITE,
  //   link: 'https://acjc.moe.edu.sg/showcase/2019/acjc-cherishes-the-class-of-2018#:~:text=Nicholas%20Chung%20has,Steward%20Award%202018', //Telegram Pofile
  // },
];

ReactDOM.render(
  <React.StrictMode>
    <Linkees cardItems={items} name={"Nicholas"}/>
  </React.StrictMode>,
  document.getElementById('root')
);
