export const CHANNEL_TYPES = {
  DRIBBLE: 'DRIBBLE',
  GITHUB: 'GITHUB',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN: 'LINKEDIN',
  TELEGRAM: 'TELEGRAM',
  TWITTER: 'TWITTER',
  WEBSITE: 'WEBSITE',
  YOUTUBE: 'YOUTUBE',
  MYWEBSITE: 'MYWEBSITE',
  GMAIL: 'GMAIL',
  NUS: 'NUS',
  ACCREDIBLE: 'ACCREDIBLE',
  LINK: 'LINK',
  NAMECARD: 'NAMECARD',
  OTHER: 'OTHER',
};
